<template>
  <div>
    <div class="img">
      <img
        :src="
          userInfo.headpic
            ? userInfo.headpic
            : '../../assets/moren_touxiang.png'
        "
        alt=""
      />
    </div>
    <div class="username img">学员：{{ tel }}</div>
    <div class="examination">
      <div class="item1">
        <img src="../../assets/exam1.svg" alt="" />
        综合练习
      </div>
      <div class="item2" @click="tab">去考试</div>
    </div>
    <el-dialog
      top="45vh"
      title="提示"
      :visible.sync="dialogVisible"
      width="30%"
      center
    >
      <div class="ssss">
        <span class="sss" v-html="val"></span>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="goexam">确 定</el-button>
      </span>
    </el-dialog>
    <div style="height: 500px"></div>
    <!-- <recordtime type="4" record="1"> </recordtime>
    <showtime type="4"></showtime> -->
    <writetime type="4"></writetime>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
  computed: {
    ...mapState({
      classid: (state) => state.classid,
      userInfo: (state) => state.userInfo,
      school: (state) => state.school,
      studyType: (state) => state.studyType,
    }),
  },
  data() {
    return {
      val: "",
      tel: "",
      dialogVisible: false,
    };
  },
  mounted() {
    var tel = String(this.userInfo.phone);
    var dh = tel.substr(0, 3) + "******" + tel.substr(8);
    this.tel = dh;
  },
  methods: {
    ...mapActions(["getMockCover"]),
    //获取当前前后日期
    getNextDate(date, day) {
      var dd = new Date(date);
      // 对于day一定要Number
      dd.setDate(dd.getDate() + Number(day));
      var y = dd.getFullYear();
      var m =
        dd.getMonth() + 1 < 10 ? "0" + (dd.getMonth() + 1) : dd.getMonth() + 1;
      var d = dd.getDate() < 10 ? "0" + dd.getDate() : dd.getDate();
      return y + "-" + m + "-" + d;
    },
    tab() {
      // if (this.studyType == 2) {
      //   this.$vux.toast.show({
      //     type: "cancel",
      //     text: "请购买课程",
      //   });
      //   return false;
      // }
      var than = this;
      this.getMockCover({
        username: this.userInfo.username,
        class: this.classid,
      }).then((res) => {
        if (res.data.examtime) {
          var myDate = new Date();
          let Year = myDate.getFullYear(); //获取完整的年份(4位,1970-????)
          let Month = myDate.getMonth() + 1; //获取当前月份(0-11,0代表1月)
          let date = myDate.getDate(); //获取当前日(1-31)
          if (Month.toString().length == 1) {
            Month = "0" + Month;
          }
          if (date.toString().length == 1) {
            date = "0" + date;
          }
          let time = `${Year}-${Month}-${date}`;
          let str;
          str = res.data.examtime.substring(0, 10);
          let end = res.data.endtime.substring(0, 10);
          let flag;
          let flag1;
          let arr;

          if (str <= time) {
            flag = true;
          } else {
            flag = false;
          }
          if (time <= end) {
            flag1 = true;
          } else {
            flag1 = false;
          }
          if (flag === flag1) {
            arr = true;
          } else {
            arr = false;
          }

          if (!arr) {
            // this.$vux.confirm.show({
            //   title: "提示",
            //   content: "考试时间</br>" + str + "至" + end,
            // });
            this.$confirm("考试时间" + str + "至" + end, "提示", {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning",
            })
              .then(() => {})
              .catch(() => {});
          } else {
            this.dialogVisible = true;
            this.val =
              str +
              "至" +
              end +
              "</br>出题类型:" +
              res.data.title +
              "</br>总题量:" +
              res.data.count +
              "</br>总分:" +
              res.data.csorc +
              "</br>合格分:" +
              res.data.pass;
          }
        } else {
          this.dialogVisible = true;
          this.val =
            "出题类型:" +
            res.data.title +
            "</br>总题量:" +
            res.data.count +
            "</br>总分:" +
            res.data.csorc +
            "</br>合格分:" +
            res.data.pass;
        }
      });
    },
    goexam() {
      this.$router.push({
        path: "/Layoutstudy/writingexercise",
        query: {
          type: 1,
          title: "综合测试",
          up: "考试",
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.img {
  display: flex;
  justify-content: center;
  img {
    border-radius: 50%;
    text-align: center;
    width: 80px;
    height: 80px;
  }
}
.ssss {
  text-align: center;
}
.sss {
  display: inline-block;
  text-align: center;
}
.username {
  margin-top: 15px;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #7c7c7c;
}
.examination {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100px;
  border-bottom: 1px solid #e1e1e1;
  .item1 {
    font-size: 18px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #222222;
    img {
      vertical-align: middle;
      margin-right: 15px;
    }
  }
  .item2 {
    cursor: pointer;
    width: 100px;
    height: 30px;
    line-height: 25px;
    text-align: center;
    background: #e9f3ff;
    border: 2px solid #137cfb;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #137cfb;
  }
}
</style>
